import React from 'react';
import icovid from '../icon/COVID.png';
import iinvalid from '../icon/INVALID.png';
import ikarantin from '../icon/KARANTIN.png';
import inosnils from '../icon/NO_SNILS.png';
import ioki from '../icon/OKI.png';
import ipr from '../icon/PR.png';
import isinagis from '../icon/SINAGIS.png';
import istacds from '../icon/STAC_DS.png';
import istatkr from '../icon/STAC_KR.png';

import inotiemk from '../icon/NOT_SENDED_TO_IEMK.png';
import iprepiemk from '../icon/PREPARE_TO_IEMK.png';
import isendiemk from '../icon/SENDED_TO_IEMK.png';
import ionsign from '../icon/ON_SIGN_1.png';
import ibl from '../icon/BL.png';
import ins from '../icon/NS.png';
import insbio from '../icon/NS_BIO.png';
import insdrlpu from '../icon/NS_DRLPU.png';
import insis from '../icon/NS_IS.png';
import inskon from '../icon/NS_KON.png';
import inso from '../icon/NS_O.png';
import insu from '../icon/NS_U.png';
import insvl from '../icon/NS_VL.png';
import iob1 from '../icon/OB1.png';
import iob2 from '../icon/OB2.png';
import iob3 from '../icon/OB3.png';
import { Popover } from '@vkontakte/vkui/dist/components/Popover/Popover';
import { Div, Text } from '@vkontakte/vkui';


const Fragment = ({txt,ic}) => {

    return (
        <Popover action="hover" placement="right"
            content={
            <Div>
                <Text>{txt}</Text>
            </Div>
            }
        ><img src={ic} width={25}/></Popover>
    )
}

const Icon = (props) => {

let txt=props.txt;
const COVID="COVID";
const INVALID="INVALID";
const KARANTIN="KARANTIN";
const NO_SNILS="NO_SNILS";
const OKI="OKI";
const PR="PR";
const SINAGIS="SINAGIS";
const STAC_DS="STAC_DS";
const STAC_KR="STAC_KR";
let imges1='';let imges2='';let imges3='';let imges4='';let imges5='';
let imges6='';let imges7='';let imges8='';let imges9='';
const NOT_SENDED_TO_IEMK="NOT_SENDED_TO_IEMK";
const PREPARE_TO_IEMK="PREPARE_TO_IEMK";
const SENDED_TO_IEMK="SENDED_TO_IEMK";
const ON_SIGN_1="ON_SIGN_1";
const BL="BL";
const NS="NS";
const NS_BIO="NS_BIO";
const NS_DRLPU="NS_DRLPU";
const NS_IS="NS_IS";
const NS_KON="NS_KON";
const NS_O="NS_O";
const NS_U="NS_U";
const NS_VL="NS_VL";
const OB1="OB1";
const OB2="OB2";
const OB3="OB3";
let imges10='';let imges11='';let imges12='';let imges13='';let imges14='';let imges15='';
let imges16='';let imges17='';let imges18='';let imges19='';let imges20='';let imges21='';
let imges22='';let imges23='';let imges24='';let imges25='';
if (txt.includes(COVID)){imges1=<Fragment txt='Обнаружен коронавирус' ic={icovid}/>}
if (txt.includes(INVALID)){imges2=<Fragment txt='Инвалидность ребенка' ic={iinvalid}/>}
if (txt.includes(KARANTIN)){imges3=<Fragment txt='Карантин' ic={ikarantin}/>}
if (txt.includes(NO_SNILS)){imges4=<Fragment txt='нет СНИЛСа в карточке' ic={inosnils}/>}
if (txt.includes(OKI)){imges5=<Fragment txt='Карантин по ОКИ' ic={ioki}/>}
if (txt.includes(PR)){imges6=<Fragment txt='Прикреплен к ЛПУ' ic={ipr}/>}
if (txt.includes(SINAGIS)){imges7=<Fragment txt='В личной карточке, раздел СИНАГИС проставлены не все прививки' ic={isinagis}/>}
if (txt.includes(STAC_DS)){imges8=<Fragment txt='Пациент лежит на дневном стационаре' ic={istacds}/>}
if (txt.includes(STAC_KR)){imges9=<Fragment txt='Пациент лежит на круглосуточном стационаре' ic={istatkr}/>}

if (txt.includes(NOT_SENDED_TO_IEMK)){imges10=<Fragment txt='Ошибка отправки случая в ИЭМК' ic={inotiemk}/>}
if (txt.includes(PREPARE_TO_IEMK)){imges11=<Fragment txt='Подготовка к отправке в ИЭМК' ic={iprepiemk}/>}
if (txt.includes(SENDED_TO_IEMK)){imges12=<Fragment txt='Случай отправлен в ИЭМК' ic={isendiemk}/>}
if (txt.includes(ON_SIGN_1)){imges13=<Fragment txt='На посещении есть подписанный протокол' ic={ionsign}/>}
if (txt.includes(BL)){imges14=<Fragment txt='Есть больничный лист' ic={ibl}/>}
if (txt.includes(NS)){imges15=<Fragment txt='Направление в Стационар' ic={ins}/>}
if (txt.includes(NS_BIO)){imges16=<Fragment txt='Направление на Биопсию' ic={insbio}/>}
if (txt.includes(NS_DRLPU)){imges17=<Fragment txt='Выписано направление не в ГБУЗ АО СГДКБ' ic={insdrlpu}/>}
if (txt.includes(NS_IS)){imges18=<Fragment txt='Направление на исследование' ic={insis}/>}
if (txt.includes(NS_KON)){imges19=<Fragment txt='Направление на консультацию' ic={inskon}/>}

if (txt.includes(NS_O)){imges20=<Fragment txt='Направление анулированно' ic={inso}/>}
if (txt.includes(NS_U)){imges21=<Fragment txt='Направление удалено' ic={insu}/>}
if (txt.includes(NS_VL)){imges22=<Fragment txt='Направление на восстановительное лечение' ic={insvl}/>}
if (txt.includes(OB1)){imges23=<Fragment txt='Обращение до 25 дней' ic={iob1}/>}
if (txt.includes(OB2)){imges24=<Fragment txt='Обращение от 25 до 29 дней' ic={iob2}/>}
if (txt.includes(OB3)){imges25=<Fragment txt='Обращение 30 дней и выше' ic={iob3}/>}
//console.log(txt.includes(COVID));
//console.log(txt.includes(PR));
//<img  src={mainLogo} alt="fireSpot"/>


	return (

<React.Fragment>
         {imges1} {imges2} {imges3} {imges4} {imges5}
         {imges6} {imges7} {imges8} {imges9} {imges10}
         {imges11} {imges12} {imges13} {imges14} {imges15}
         {imges16} {imges17} {imges18} {imges19} {imges20}
         {imges21} {imges22} {imges23} {imges24} {imges25}
         
         
</React.Fragment>
 
	);
}

export default Icon;


