import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Div, Group, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Visit = (props) => {

    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isVis, setIsVis] = React.useState(null);

  const fetchLoadPatient = () => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    setIsVis(null)
    setIsLoading(true);

    axios.post(ip.lk.ipaddr+"lkvrachHTalon/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        id: props.id,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(({ data }) => {
        setIsLoading(false)
        setIsVis(JSON.parse(data[0].DANN))
        console.log(JSON.parse(data[0].DANN))
        //console.log(data)
    })
    .catch((err) => {
        console.log(err.message);
    });
  };

  React.useEffect(fetchLoadPatient, []);


  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Амб. посещение</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Амб. посещение</PanelHeaderContent>
        </PanelHeader>
        <Group>
        {
            isVis && <div>
            <div >{isVis.full_dat}</div>
            <div >{isVis.doctor}</div>
            <div >{isVis.mkb_code} - {isVis.mkb_text}</div>
            {
                isVis.protokol && isVis.protokol.map((prot,index) => {
                    return(
                        <div key={index}>
                            <div style={{textAlign:"center",fontWeight: "bold",padding:"5px 0 5px 0"}}>{prot.text}</div>
                            {prot.otvet && prot.otvet.map((otv,index) => {
                                return(
                                    <div key={index}>
                                        <div style={{backgroundColor:"#a8ffd2",color:"black"}}>{otv.question}</div>
                                        <div >{otv.answer}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })
            }
            </div>
        }
        {/*
            isVac && isVac.map((vacs,index) => {
            console.log(vacs)
            return(
              <div key={index} >
                <div style={{textAlign: 'center',fontWeight: "bold",paddingBottom:5, backgroundColor:"#DFDAFF"}}>{vacs.disease_text}</div>
                {vacs.vac && vacs.vac.map((etap) => {
                    return(
                        <div style={{backgroundColor:"#FFE5CB" }} key={etap.disease_id}>{etap.act} - факт {etap.dat} - план {etap.plan_dat}</div>
                    )
                })}
              </div>
            )
          })
        */}
      </Group>
    </>
  );
};

export default Visit;
