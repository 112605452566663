import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Button, DateInput, Div, FormItem, Group, LocaleProvider, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const VseHistory = (props) => {
    console.log(props)

    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(true);
  const [dats, setIsDatS] = React.useState(null);
  const [datpo, setIsDatPo] = React.useState(null);

  const [value1, setValue1] = React.useState(null);
  const [value2, setValue2] = React.useState(null);

  const fetchLoadPatient = () => {
    props.setIsHistory(null)
    setIsLoading(true);

    var dattt =new Date() 
    var datttf = dattt.getDate() + "." + (dattt.getMonth()+1) + "." + dattt.getFullYear();
    var datt21 =new Date()
    var datt =new Date(datt21.setDate(datt21.getDate() - 365)) 
    var dattf = datt.getDate() + "." + (datt.getMonth()+1) + "." + datt.getFullYear();
    setIsDatS(dattf);setIsDatPo(datttf);
    setValue1(dattt);setValue2(datt);
    loaddata(dattf,datttf)
  };

  const loaddata = (dat1,dat2) => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    
    axios.post(ip.lk.ipaddr+"lkvrachHistory/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
      patid: props.patid,
      dat1: dat1,
      dat2: dat2
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  .then(({ data }) => {
      setIsLoading(false)
      //dispatch(setLoadVizov(data));
      //setIsPatient(data[0])
      props.setIsHistory(data)
      //console.log(data)
  })
  .catch((err) => {
      console.log(err.message);
  });
  }

  const BtnDatLoad = () => {
    /*var datttf = value1.getDate() + "." + (value1.getMonth()+1) + "." + value1.getFullYear();
    var dattf = value2.getDate() + "." + (value2.getMonth()+1) + "." + value2.getFullYear();*/
    //console.log(dattf+' - '+datttf)
    //console.log(value1.toJSON().slice(0,10).split('-').reverse().join('.')+' - '+value2.toJSON().slice(0,10).split('-').reverse().join('.'))
    props.setIsHistory(null)
    setIsLoading(true);
    loaddata(value2.toJSON().slice(0,10).split('-').reverse().join('.'), value1.toJSON().slice(0,10).split('-').reverse().join('.'))
  }

  React.useEffect(fetchLoadPatient, []);

  const ClickHist = (KEYID,perexod) =>{
    //console.log('KEYID - '+KEYID+'perexod - '+perexod)
    props.setKeyHistory(KEYID)
    props.setActivePanel(perexod)
  }

  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>История лечения</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>История лечения</PanelHeaderContent>
        </PanelHeader>
        <Group>
        <div style={{ width: "100%", margin: "0 auto", padding: 10 }}>
            <div style={{ float: "left", width: "50%", textAlign: "center",fontSize:20}}>
            <FormItem>
              <div style={{ display: 'flex' }}>
              c - 
                <LocaleProvider value='ru'>
                  <DateInput
                    value={value2}
                    onChange={setValue2}
                  />
                </LocaleProvider>
              </div>
            </FormItem>
            </div>
            
            <div style={{ float: "right", width: "50%", textAlign: "center",fontSize:20 }}>
            <FormItem>
          <div style={{ display: 'flex' }}>
          по - 
            <LocaleProvider value='ru'>
              <DateInput
                value={value1}
                onChange={setValue1}
              />
            </LocaleProvider>
          </div>
        </FormItem>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center",fontSize:20, paddingBottom:'10px'}} >
              <Button onClick={() => BtnDatLoad()}>Применить</Button>
          </div>
        
      {
        props.history && props.history.map((hist,index) => {

          let opis=''; let color='#FFCADD'; let perexod=''
            if (hist.REC_TYPE=='VISIT'){opis='Амб.история'; color='#FFCADD'; perexod='visit'}
            else if (hist.REC_TYPE=='RESEARCH'){opis='Диагностика'; color='#DFDAFF'; perexod='res'}
            else if (hist.REC_TYPE=='LAB'){opis='Лабаратория'; color='#FFE5CB'; perexod='lab'}
            else if (hist.REC_TYPE=='VACCINATION'){opis='Вакцинация'; color='#a8ffd2'; perexod='vac'}

          return (
            <SimpleCell subtitle={hist.DAT_STRING} key={index} style={{backgroundColor: color, color:"black"}} onClick={() => ClickHist(hist.KEYID,perexod)}>
            {hist.REC_TEXT}
            </SimpleCell>
          )
        })
        
      }
      </Group>
    </>
  );
};

export default VseHistory;
