import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import "./kartpac.css";
import { Div, Group, PanelHeader, PanelHeaderBack, PanelHeaderContent, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Kartpac = (props) => {
    console.log(props)
    const dispatch = useDispatch();
    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isPatient, setIsPatient] = React.useState(null);

  const fetchLoadPatient = () => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    setIsLoading(true);
    axios.post(ip.lk.ipaddr+"lkvrachKartPac/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        patid: props.patid
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(({ data }) => {
        setIsLoading(false)
        //dispatch(setLoadVizov(data));
        setIsPatient(data[0])
        console.log(data)
    })
    .catch((err) => {
        console.log(err.message);
    });
  };

  React.useEffect(fetchLoadPatient, []);

  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Карта пациента</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Карта пациента</PanelHeaderContent>
        </PanelHeader>
      {isPatient && <Div>
        <Group style={{backgroundColor:"#DFDAFF"}}>
          <div className="zag">Личные данные</div>
          <div className="text">Фамилия Имя Отчество</div>
          <div className="opis">{isPatient.FIO}</div>
          {/*<Divider />*/}
          <div className="text">Дата рождения</div>
          <div className="opis">{isPatient.BDATE}</div>
          {/*<Divider />*/}
          <div className="text">Возраст</div>
          <div className="opis">{isPatient.AGE_VIS}</div>
          {/*<Divider />*/}
          <div className="text">Место рождения</div>
          <div className="opis">{isPatient.BORN_PLACE}</div>
          {/*<Divider />*/}
          <div className="text">Пол</div>
          <div className="opis">{isPatient.SEX_NAME}</div>
          {/*<Divider />*/}
          <div className="text">СНИЛС</div>
          <div className="opis">{isPatient.SNILS}</div>
          {/*<Divider />*/}
          <div className="text">Номер участка</div>
          <div className="opis">{isPatient.AREA}</div>
          {/*<Divider />*/}
          <div className="text">Телефон дом.</div>
          <div className="opis">{isPatient.PHONE}</div>
          {/*<Divider />*/}
          <div className="text">Телефон сот.</div>
          <div className="opis">{isPatient.PHONES}</div>
          {/*<Divider />*/}
          <div className="text">Телефон родств.</div>
          <div className="opis">{isPatient.RELPHONE}</div>
          {/*<Divider />*/}
          <div className="text">Комментарий</div>
          <div className="opis">{isPatient.KFN}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#FFCADD"}}>
          <div className="zag">Документ удостовкряющий личность</div>
          <div className="text">Тип документа</div>
          <div className="opis">{isPatient.DOCUM}</div>
          {/*<Divider />*/}
          <div className="text">Серия</div>
          <div className="opis">{isPatient.PASSSER}</div>
          {/*<Divider />*/}
          <div className="text">Номер</div>
          <div className="opis">{isPatient.PASSNUMB}</div>
          {/*<Divider />*/}
          <div className="text">Кем выдан</div>
          <div className="opis">{isPatient.PASSISSUE}</div>
          {/*<Divider />*/}
          <div className="text">Когда выдан</div>
          <div className="opis">{isPatient.PASSDATE}</div>
          {/*<Divider />*/}
          <div className="text">Соц. статус</div>
          <div className="opis">{isPatient.SOC_STAT}</div>
          {/*<Divider />*/}
          <div className="text">Место работы</div>
          <div className="opis">{isPatient.LASTWRK}</div>
          {/*<Divider />*/}
          <div className="text">Должность</div>
          <div className="opis">{isPatient.POSITION}</div>
          {/*<Divider />*/}
          <div className="text">Гражданство</div>
          <div className="opis">{isPatient.ROD}</div>
          {/*<Divider />*/}
          <div className="text">Место рождения</div>
          <div className="opis">{isPatient.MROD}</div>
          {/*<Divider />*/}
          <div className="text">Ребенок-инвалид</div>
          <div className="opis">{isPatient.INV}</div>
          {/*<Divider />*/}
          <div className="text">Инвалид с</div>
          <div className="opis">{isPatient.INVDS}</div>
          {/*<Divider />*/}
          <div className="text">Инвалид по</div>
          <div className="opis">{isPatient.INVDP}</div>
          {/*<Divider />*/}
          <div className="text">Инвалидность с</div>
          <div className="opis">{isPatient.INVP}</div>
          {/*<Divider />*/}
          <div className="text">Школа/сад</div>
          <div className="opis">{isPatient.SCHOOL}</div>
          {/*<Divider />*/}
          <div className="text">Порядковый номер ребенка при многоплодных родах</div>
          <div className="opis">{isPatient.NOVOR}</div>
          {/*<Divider />*/}
          <div className="text">Место нах.карты</div>
          <div className="opis">{isPatient.CARD_PLACE}</div>
          {/*<Divider />*/}
          <div className="text">Реальность</div>
          <div className="opis">{isPatient.REAL}</div>
          {/*<Divider />*/}
          <div className="text">Двойник</div>
          <div className="opis">{isPatient.PALLIATIV}</div>
          {/*<Divider />*/}
          <div className="text">COVID-статус</div>
          <div className="opis">{isPatient.COVID_STATUS}</div>
          {/*<Divider />*/}
          <div className="text">Контакт по COVID-19</div>
          <div className="opis">{isPatient.K_COV}</div>
          {/*<Divider />*/}
          <div className="text">Карантин по ОКИ с</div>
          <div className="opis">{isPatient.OKI}</div>
          {/*<Divider />*/}
          <div className="text">Форма30</div>
          <div className="opis">{isPatient.F30}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#D1F1FF"}}>
          <div className="zag">Прикрепление к ЛПУ</div>
          <div className="text">Номер заявления</div>
          <div className="opis">{isPatient.REQ_NUM}</div>
          {/*<Divider />*/}
          <div className="text">Дата заявления</div>
          <div className="opis">{isPatient.REQ_DATE}</div>
          {/*<Divider />*/}
          <div className="text">Причина</div>
          <div className="opis">{isPatient.V_PR}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#FFE5CB"}}>
          <div className="zag">Информированное согласие</div>
          <div className="text">Наличие информированного согласия</div>
          <div className="opis">{isPatient.AGRTOPR}</div>
          {/*<Divider />*/}
          <div className="text">Дата информированного согласия</div>
          <div className="opis">{isPatient.DATAOFAGR}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#DFDAFF"}}>
          <div className="zag">Данные представителя пациента</div>
          <div className="text">Статус представителя</div>
          <div className="opis">{isPatient.PR1}</div>
          {/*<Divider />*/}
          <div className="text">Фамилия представителя</div>
          <div className="opis">{isPatient.F_P}</div>
          {/*<Divider />*/}
          <div className="text">Имя представителя</div>
          <div className="opis">{isPatient.I_P}</div>
          {/*<Divider />*/}
          <div className="text">Отчество представителя</div>
          <div className="opis">{isPatient.O_P}</div>
          {/*<Divider />*/}
          <div className="text">Д.р. представителя</div>
          <div className="opis">{isPatient.BD_P}</div>
          {/*<Divider />*/}
          <div className="text">Гражданство</div>
          <div className="opis">{isPatient.GR_P}</div>
          {/*<Divider />*/}
          <div className="text">Место рождения</div>
          <div className="opis">{isPatient.BORNPLACE}</div>
          {/*<Divider />*/}
          <div className="text">СНИЛС</div>
          <div className="opis">{isPatient.SNILS_P}</div>
          {/*<Divider />*/}
          <div className="text">Паспорт</div>
          <div className="opis">{isPatient.PASP_P}</div>
          {/*<Divider />*/}
          <div className="text">Серия и номер полиса</div>
          <div className="opis">{isPatient.SN_P_M}</div>
          {/*<Divider />*/}
          <div className="text">Место работы</div>
          <div className="opis">{isPatient.MESTO_RAB_P}</div>
          {/*<Divider />*/}
          <div className="text">Должность</div>
          <div className="opis">{isPatient.DOL}</div>
          {/*<Divider />*/}
          <div className="text">Телефон</div>
          <div className="opis">{isPatient.PHONE_P}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#FFCADD"}}>
          <div className="zag">Второй представитель пациента</div>
          <div className="text">Статус представителя</div>
          <div className="opis">{isPatient.PR2}</div>
          {/*<Divider />*/}
          <div className="text">Фамилия представителя</div>
          <div className="opis">{isPatient.F2_P}</div>
          {/*<Divider />*/}
          <div className="text">Имя представителя</div>
          <div className="opis">{isPatient.I2_P}</div>
          {/*<Divider />*/}
          <div className="text">Отчество представителя</div>
          <div className="opis">{isPatient.O2_P}</div>
          {/*<Divider />*/}
          <div className="text">Д.р. представителя</div>
          <div className="opis">{isPatient.BD2_P}</div>
          {/*<Divider />*/}
          <div className="text">Документ</div>
          <div className="opis">{isPatient.PASP2_P}</div>
          {/*<Divider />*/}
          <div className="text">Полис</div>
          <div className="opis">{isPatient.POL_P2}</div>
          {/*<Divider />*/}
          <div className="text">СНИЛС</div>
          <div className="opis">{isPatient.SNILS2_P}</div>
          {/*<Divider />*/}
          <div className="text">Место работы</div>
          <div className="opis">{isPatient.MESTO_RAB_P2}</div>
          {/*<Divider />*/}
          <div className="text">Должность</div>
          <div className="opis">{isPatient.DOL2}</div>
          {/*<Divider />*/}
          <div className="text">Телефон</div>
          <div className="opis">{isPatient.PHONE_P2}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#D1F1FF"}}>
          <div className="zag">Для покинувших территорию Украины</div>
          <div className="text">Место проживания</div>
          <div className="opis">{isPatient.UKR_PLACE}</div>
          {/*<Divider />*/}
          <div className="text">Тип документа</div>
          <div className="opis">{isPatient.UKR_DOC_TYPE}</div>
          {/*<Divider />*/}
          <div className="text">Номер документа</div>
          <div className="opis">{isPatient.UKR_DOC_SER}</div>
          {/*<Divider />*/}
          <div className="text">Серия документа</div>
          <div className="opis">{isPatient.UKR_DOC_NUM}</div>
          {/*<Divider />*/}
          <div className="text">Кем/когда выдан</div>
          <div className="opis">{isPatient.UKR_DOC_INFO}</div>
          {/*<Divider />*/}
          <div className="text">Статус</div>
          <div className="opis">{isPatient.UKR_STATUS}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#FFE5CB"}}>
          <div className="zag">Адрес прописки</div>
          <div className="text">Тип адреса</div>
          <div className="opis">{isPatient.TADR}</div>
          {/*<Divider />*/}
          <div className="text">Субъект РФ</div>
          <div className="opis">{isPatient.OBL1}</div>
          {/*<Divider />*/}
          <div className="text">Район</div>
          <div className="opis">{isPatient.REG1}</div>
          {/*<Divider />*/}
          <div className="text">Город</div>
          <div className="opis">{isPatient.GOR1}</div>
          {/*<Divider />*/}
          <div className="text">Населенный пункт</div>
          <div className="opis">{isPatient.TOWN1}</div>
          {/*<Divider />*/}
          <div className="text">Улица</div>
          <div className="opis">{isPatient.UL1}</div>
          {/*<Divider />*/}
          <div className="text">Дом</div>
          <div className="opis">{isPatient.D1}</div>
          {/*<Divider />*/}
          <div className="text">Корпус</div>
          <div className="opis">{isPatient.K1}</div>
          {/*<Divider />*/}
          <div className="text">Квартира</div>
          <div className="opis">{isPatient.KV1}</div>
          {/*<Divider />*/}
          <div className="text">Житель</div>
          <div className="opis">{isPatient.G1}</div>
          {/*<Divider />*/}
          <div className="text">Комментарий</div>
          <div className="opis">{isPatient.KOM1}</div>
          {/*<Divider />*/}
        </Group>
        <Group style={{backgroundColor:"#DFDAFF"}}>
          <div className="zag">Адрес проживания</div>
          <div className="text">Тип адреса</div>
          <div className="opis">{isPatient.TADR2}</div>
          {/*<Divider />*/}
          <div className="text">Субъект РФ</div>
          <div className="opis">{isPatient.OBL2}</div>
          {/*<Divider />*/}
          <div className="text">Район</div>
          <div className="opis">{isPatient.REG2}</div>
          {/*<Divider />*/}
          <div className="text">Город</div>
          <div className="opis">{isPatient.GOR2}</div>
          {/*<Divider />*/}
          <div className="text">Населенный пункт</div>
          <div className="opis">{isPatient.TOWN2}</div>
          {/*<Divider />*/}
          <div className="text">Улица</div>
          <div className="opis">{isPatient.UL2}</div>
          {/*<Divider />*/}
          <div className="text">Дом</div>
          <div className="opis">{isPatient.D2}</div>
          {/*<Divider />*/}
          <div className="text">Корпус</div>
          <div className="opis">{isPatient.K2}</div>
          {/*<Divider />*/}
          <div className="text">Квартира</div>
          <div className="opis">{isPatient.KV2}</div>
          {/*<Divider />*/}
          <div className="text">Житель</div>
          <div className="opis">{isPatient.G2}</div>
          {/*<Divider />*/}
          <div className="text">Комментарий</div>
          <div className="opis">{isPatient.KOM2}</div>
          {/*<Divider />*/}
        </Group>
    </Div>}
    </>
  );
};

export default Kartpac;
