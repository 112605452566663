import React from 'react';
import {  Panel,  View } from '@vkontakte/vkui';

import EscheGl from './EscheGl';
import Poiskpac from './piskpac/Poiskpac';
import Vrach from './Vrach';
import Settings from './Settings';
import OpenUvedom from '../Alert/Uvedomln';
import Kartpac from '../patient/Kartpac';
import VseHistory from '../history/VseHistory';
import Lab from '../history/Lab';
import Res from '../history/Research';
import Vac from '../history/Vac';
import Visit from '../history/Talon';

const Esche = (props) => {

	  const [activePanel, setActivePanel] = React.useState('eschegl');
	  const [searchPac, setSearchPac] = React.useState(null);
	  const [kartpac, setKartPac] = React.useState(null);
	  const [history, setIsHistory] = React.useState(null);
	  const [keyhistory, setKeyHistory] = React.useState(null);

    return(
        <View id="esche" activePanel="esche">
			  <Panel id="esche">
			  	<View activePanel={activePanel}>
					<Panel id="eschegl">
						<EscheGl setActivePanel={setActivePanel}/>
					</Panel>
					{/****************************** */}
					<Panel id="poiskpac">
						<Poiskpac setActivePanel={setActivePanel} panelBack={'eschegl'} searchPac={searchPac} setSearchPac={setSearchPac} setActiveStoryEx={props.setActiveStoryEx} setKartPac={setKartPac} setPing={props.setPing}/>
					</Panel>
					<Panel id="kartpac">
						<Kartpac setActivePanel={setActivePanel} patid={kartpac} panelBack={'poiskpac'} setPing={props.setPing}/>
					</Panel>

					<Panel id="vsehistory">
						<VseHistory setActivePanel={setActivePanel} patid={kartpac} panelBack={'poiskpac'} setIsHistory={setIsHistory} history={history} setKeyHistory={setKeyHistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="lab">
						<Lab setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="res">
						<Res setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="vac">
						<Vac setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="visit">
						<Visit setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					{/**История, карточка, добавить вызов, запись на прием */}

					<Panel id="vrach">
						<Vrach setActivePanel={setActivePanel} panelBack={'eschegl'} setPing={props.setPing}/>
					</Panel>
					<Panel id="settings">
						<Settings setActivePanel={setActivePanel} panelBack={'eschegl'} setPing={props.setPing}/>
					</Panel>
				</View>
				
			  </Panel>
			</View>
    )
}

export default Esche;