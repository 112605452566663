import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Div, Group, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Lab = (props) => {

    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isLab, setIsLab] = React.useState(null);

  const fetchLoadPatient = () => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    
    setIsLab(null)
    setIsLoading(true);

    axios.post(ip.lk.ipaddr+"lkvrachHLab/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        id: props.id,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(({ data }) => {
        setIsLoading(false)
        setIsLab(JSON.parse(data[0].LABS))
        //console.log(JSON.parse(data[0].LABS))
    })
    .catch((err) => {
        console.log(err.message);
    });
  };

  React.useEffect(fetchLoadPatient, []);


  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Лабаратория</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Лабаратория</PanelHeaderContent>
        </PanelHeader>
        <Group>
      {isLab && <Div>
        <div style={{padding:"5px 0 5px 0"}}>Дата направления - {isLab.reg_dat}</div>
        <div style={{padding:"5px 0 5px 0"}}>Кто направил - {isLab.direct_doctor}, {isLab.direct}</div>
        <div style={{padding:"5px 0 5px 0"}}>Дата выполения - {isLab.finish_dat}</div>
        <div style={{padding:"5px 0 5px 0"}}>Кто выполнил - {isLab.fio}</div>
        {
            isLab.labresult && isLab.labresult.map((labs,index) => {
            let txt='';
            let color='#ddffca';
            if(labs.dist == '>'){color='#FFCADD'}
            if(labs.RESULT == null || labs.RESULT == ''){txt=<div style={{ textAlign:"center",fontWeight: "bold", padding:"10px 0 10px 0",backgroundColor:"#DFDAFF",color:"black"}}>{labs.measur}</div>}
            else{
            txt=<div style={{ width: "100%", backgroundColor:color, padding:"5px 0 5px 0",color:"black"}}>
            {labs.measur} - {labs.RESULT} - {labs.norm}
            </div>
          }
            return(
              <div key={index} >
                {txt}
              </div>
            )
          })
        }
      </Div>}
      </Group>
    </>
  );
};

export default Lab;
