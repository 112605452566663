import React from 'react';
import PropTypes from 'prop-types';

import {  Button,  FormItem,  FormLayout,  Group,  Input,  Panel, PanelHeader } from '@vkontakte/vkui';
import loads from '../img/cat-loading.gif';
import "./LoadUser.css";
import { useSelector } from "react-redux";
import { PingTest } from '../function/ping';
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const ErrPing = props => {

	const ip = useSelector(({lk}) => {
		return{
		  lk: lk
		}
	  });

	const BtmUpdt =()=>{
		PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
	}

    return(
	<Panel id={props.id}>
		<div style={{padding:'50px 0 10px 0'}}></div>
			<Group>
				<div style={{textAlign:'center'}}>
				<div style={{padding:'10px 0 10px 0'}}>Ой! произошла ошибка. Сервер недоступен. Письмо уже отправленно в ИТ отдел. 
				Закройте полностью приложение ВК и попробуйте зайти через 5 минут. Или нажмите кнопку обновить</div>
				<div><Button onClick={() => BtmUpdt()}>Обновить</Button></div>
				<img src={loads} alt="LoadCat"/>
				</div>
			</Group>
	</Panel>
)};

ErrPing.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
};

export default ErrPing;

