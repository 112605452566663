import React from 'react';
import PropTypes from 'prop-types';
import { Group, Panel, PanelHeader, Placeholder, RichCell, View,Avatar, SimpleCell, Header, PanelHeaderBack } from '@vkontakte/vkui';
import { Icon24ChevronRight, Icon28ClipOutline } from '@vkontakte/icons';
import { Icon28MessageOutline } from '@vkontakte/icons';
import { useSelector, useDispatch } from "react-redux";
import VedomGl from './VedomGl';
import Kartpac from '../patient/Kartpac';
import VseHistory from '../history/VseHistory';
import Lab from '../history/Lab';
import Res from '../history/Research';
import Vac from '../history/Vac';
import Visit from '../history/Talon';
import Karantin from '../Karantin/Karantin';


const Vedom = (props) => {

	  const [activePanel, setActivePanel] = React.useState('vedomgl');
	  const [kartpac, setKartPac] = React.useState(null);
	  const [history, setIsHistory] = React.useState(null);
	  const [keyhistory, setKeyHistory] = React.useState(null);


    return(
        <View id="vedom" activePanel="vedom">
			  <Panel id="vedom">
			  	<View activePanel={activePanel}>
					<Panel id="vedomgl">
						<VedomGl setActivePanel={setActivePanel} setKartPac={setKartPac} setPing={props.setPing}/>
					</Panel>
					<Panel id="kartpac">
						<Kartpac setActivePanel={setActivePanel} patid={kartpac} panelBack={'vedomgl'} setPing={props.setPing}/>
					</Panel>
					<Panel id="karantin">
						<Karantin setActivePanel={setActivePanel} patid={kartpac} panelBack={'vedomgl'} setPing={props.setPing}/>
					</Panel>
					<Panel id="vsehistory">
						<VseHistory setActivePanel={setActivePanel} patid={kartpac} panelBack={'vedomgl'} setIsHistory={setIsHistory} history={history} setKeyHistory={setKeyHistory} setPing={props.setPing}/>
					</Panel>

					<Panel id="lab">
						<Lab setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="res">
						<Res setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="vac">
						<Vac setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="visit">
						<Visit setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					
				</View>
			  </Panel>
			</View>
    )
}

export default Vedom;