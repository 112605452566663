import React from 'react';
import { Group,  PanelHeader, RichCell, Avatar, SimpleCell, Header, PanelHeaderContent, Div } from '@vkontakte/vkui';
import { Icon24ChevronRight } from '@vkontakte/icons';
import { useSelector, useDispatch } from "react-redux";

const EscheGl = (props) => {

  const ip = useSelector(({lk}) => {
    return{
      lk: lk
    }});

  return (
    <>
      <PanelHeader>
          <PanelHeaderContent status={ip.lk.docdeptxt}>СГДКБ</PanelHeaderContent>
      </PanelHeader>
      <Div></Div>
      <Group>
        <SimpleCell  indicator={<Icon24ChevronRight />}  onClick={() => props.setActivePanel("poiskpac")}>Поиск пациента</SimpleCell>
      </Group>
      <Group>
        <SimpleCell  indicator={<Icon24ChevronRight />}  onClick={() => props.setActivePanel("vrach")}>Смена врача</SimpleCell>
        <SimpleCell indicator={<Icon24ChevronRight />} onClick={() => props.setActivePanel("settings")}>Настройки</SimpleCell>
      </Group>
      <Group>
        <SimpleCell indicator={<Icon24ChevronRight />}>О программе</SimpleCell>
      </Group>
    </>
  );
};

export default EscheGl;
