import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Div, Group, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Res = (props) => {

    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isRes, setIsRes] = React.useState(null);

  const fetchLoadPatient = () => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    setIsRes(null)
    setIsLoading(true);

    axios.post(ip.lk.ipaddr+"lkvrachHResearch/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        id: props.id,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(({ data }) => {
        setIsLoading(false)
        setIsRes(JSON.parse(data[0].DANN))
        //console.log(JSON.parse(data[0].DANN))
    })
    .catch((err) => {
        console.log(err.message);
    });
  };

  React.useEffect(fetchLoadPatient, []);


  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Исследование</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Исследование</PanelHeaderContent>
        </PanelHeader>
        <Group>
      {isRes && <Div>
        <div style={{padding:"5px 0 5px 0"}}>Дата - {isRes[0].DAT} {isRes[0].TIME}</div>
        <div style={{padding:"5px 0 5px 0"}}>Врач - {isRes[0].doctor}</div>
        <div style={{padding:"5px 0 5px 0"}}>М/с - {isRes[0].NURSE}</div>
        <div style={{textAlign:"center",fontWeight: "bold",padding:"5px 0 5px 0"}}>{isRes[0].res_type}</div>
        {
            isRes[0].PROTOKOL && isRes[0].PROTOKOL.map((rese,index) => {
            return(
              <div key={index} >
                <div style={{backgroundColor:"#a8ffd2",color:"black"}}>{rese.question}</div>
                <div >{rese.answer}</div>
              </div>
            )
          })
        }
      </Div>}
      </Group>
    </>
  );
};

export default Res;
