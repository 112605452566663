import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Cell, Counter, Epic, Group, Panel, PanelHeader, PanelHeaderBack, Placeholder,  SplitCol,  SplitLayout,  Tabbar, TabbarItem, View } from '@vkontakte/vkui';
import { Icon28ClipOutline, Icon28HomeOutline, Icon28ListBulletSquareOutline, Icon28Menu, Icon28NewsfeedOutline, Icon28WriteOutline } from '@vkontakte/icons';
import Home from './Home/Home';
import Vizov from './Vizov/Vizov';
import Esche from './esche/Esche';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Vedom from './vedom/Vedom';

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Example = (props) => {
	const dispatch = useDispatch();

	const ip = useSelector(({lk}) => {
		return{
		  lk: lk
		}
	  });
	//debugger;
	//const platform = usePlatform();
	//const { viewWidth } = useAdaptivityConditionalRender();
	const [activeStory, setActiveStory] = React.useState('home');
	const onStoryChange = (e) => {
		setActiveStory(e.currentTarget.dataset.story);
		if (e.currentTarget.dataset.story === 'home'){
			/*dispatch(setLoadZapLoader(true))
                axios.get(ip.lk.ipaddr2+"zapinfo/2/"+md5(ip.lk.kkk+''+fff)+"/"+ip.lk.vk.id)
                  .then(({ data }) => {
                    dispatch(setLoadZap(data))
                    })
                  .catch((err) => {
                    console.log(err.message);
                  });

			dispatch(setLoadVizovLoader(true))
			axios.get(ip.lk.ipaddr2+"vizovinfo/2/"+md5(ip.lk.kkk+''+fff)+"/"+ip.lk.vk.id)
				.then(({ data }) => {
					dispatch(setLoadVizov(data))
				})
				.catch((err) => {
					console.log(err.message);
				});*/
		}
	}
	//const isVKCOM = platform !== Platform.VKCOM;
  
	return (
	  <SplitLayout
		header={<PanelHeader separator={false} />}
		style={{ justifyContent: 'center' }}
    popout={props.popout}
	  >
	
		<SplitCol width="100%">
		  <Epic
			activeStory={activeStory}
			tabbar={
			  (
				<Tabbar >
				  <TabbarItem
					onClick={onStoryChange}
					selected={activeStory === 'home'}
					data-story="home"
					text="Главная"
				  >
				  	<Icon28HomeOutline />
				  </TabbarItem>
				  <TabbarItem
					onClick={onStoryChange}
					selected={activeStory === 'vizov'}
					data-story="vizov"
					text="Вызов врача"
				  >
				 	<Icon28WriteOutline />
				  </TabbarItem>
				  <TabbarItem
					onClick={onStoryChange}
					selected={activeStory === 'vedom'}
					data-story="vedom"
					text="Ведомость"
				  >
					<Icon28ListBulletSquareOutline />
				  </TabbarItem>
				  
				  <TabbarItem
					onClick={onStoryChange}
					selected={activeStory === 'esche'}
					data-story="esche"
					text="Еще"
				  >
					<Icon28Menu />
				  </TabbarItem>
				</Tabbar>
			  )
			}
		  >
			<Home id="home" err={props.err}/>
      		<Vedom id="vedom" setActiveStoryEx={setActiveStory} setPing={props.setPing}/>
			<Vizov id="vizov" setActiveStoryEx={setActiveStory} setPing={props.setPing}/>
			<Esche id="esche" setActiveStoryEx={setActiveStory} setPing={props.setPing}/>
		  </Epic>
		</SplitCol>
	  </SplitLayout>
	);
  };

  export default Example;