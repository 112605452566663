import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Div, FormItem, FormLayout, Group, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { Icon24CheckCircleOutline } from "@vkontakte/icons";
import { setUpdateDocdepId, setUpdateDocdepText } from "../../redux/action/lk";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Vrach = (props) => {
  const dispatch = useDispatch();
    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});


  let ckikDocButton = (DOCDEPID,DOC) => {
    console.log(DOCDEPID+' - '+DOC)
    dispatch(setUpdateDocdepId(DOCDEPID))
    let txt=DOCDEPID+' - '+DOC
    dispatch(setUpdateDocdepText(txt))
  }
 

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Смена врача</PanelHeaderContent>
        </PanelHeader>
        <Group>
        
        <FormLayout>
        <FormItem top="Врач">
        {
          ip.lk.docdep && ip.lk.docdep.map((doc, index) => {
            if (doc.DOCDEPID==ip.lk.docdepid){
              return <SimpleCell indicator={<Icon24CheckCircleOutline />} style={{background:"#BBFFBB",color:"black"}} key={index}>
              {doc.DOCDEPID} - {doc.DOC}
            </SimpleCell>
            }else{
              return <SimpleCell onClick={() => ckikDocButton(doc.DOCDEPID,doc.DOC)} key={index}>
              {doc.DOCDEPID} - {doc.DOC}
            </SimpleCell>
            }
          })

        }

        </FormItem>
      </FormLayout>

      </Group>
    </>
  );
};

export default Vrach;
