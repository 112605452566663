export const setAddVk = (name) => ({
    type:'ADD_VK',
    payload: name
})

export const setAddManId = (name) => ({
    type:'ADD_MAN_ID',
    payload: name
})
export const setAddDocDep = (name) => ({
    type:'ADD_DOCDEP',
    payload: name
})
export const setUpdateDocdepId = (name) => ({
    type:'UPDATE_DOCDEP_ID',
    payload: name
})
export const setUpdateDocdepText = (name) => ({
    type:'UPDATE_DOCDEP_TEXT',
    payload: name
})
export const setUpdateNotiff = (name) => ({
    type:'UPDATE_NOTIFF',
    payload: name
})

export const setLoadDocDep = (name) => ({
    type:'LOAD_DOCDEP',
    payload: name
})

export const setLoadUpdateDocDep = (name) => ({
    type:'LOAD_UPDATE_DOCDEP',
    payload: name
})

export const setUpdateDocDep = (name) => ({
    type:'UPDATE_DOCDEP',
    payload: name
})

export const setLoadVizov = (name) => ({
    type:'LOAD_VIZOV',
    payload: name
})

export const setLoadVedomost = (name) => ({
    type:'LOAD_VEDOMOST',
    payload: name
})

export const setKartPacId = (name) => ({
    type:'KARTPACID',
    payload: name
})

export const setPing = (name) => ({
    type:'PING',
    payload: name
})

