import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Alert, Button, Card, DateInput, Div, FormItem, FormLayout, Group, Input, LocaleProvider, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../../function/ping";
import Icon from "../../Icon";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Poiskpac = (props) => {

    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});
          
  const [isLoading, setIsLoading] = React.useState(false);
  const [errtxt, setErrTxt] = React.useState(null);
  const [dr, setDR] = React.useState(null);
  const [fam, setFam] = React.useState('');
  const [nam, setName] = React.useState('');
  const [otch, setOtch] = React.useState('');
  
  let famInput = React.createRef();
  let namInput = React.createRef();
  let otchInput = React.createRef();
  let famVvod = () => {
     setFam(famInput.current.value)
	}
  let nameVvod = () => {
     setName(namInput.current.value)
	}
  let otchVvod = () => {
     setOtch(otchInput.current.value)
	}

  let SerchPac = () =>{
    let errtxt='Вы не заполнили поля:';
    let errcount=0;
    if (fam==null || fam==''){errtxt=errtxt+' Фамилия,';errcount=1}
    if (nam==null || nam==''){errtxt=errtxt+' Имя,';errcount=1}
    if (otch==null || otch==''){errtxt=errtxt+' Отчетво,';errcount=1}
    if (dr==null || dr==''){errtxt=errtxt+' Дата рождения';errcount=1}
    if (errcount==1){console.log(errtxt);setErrTxt(errtxt)}
    else {
      /**проверка на доступность */
      PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
      setErrTxt(null)
      setIsLoading(true);
      axios.post(ip.lk.ipaddr+"lkvrachSearchPac/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        fam: fam,
        nam: nam,
        otch: otch,
        dr:dr.toJSON().slice(0,10).split('-').reverse().join('.')
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(({ data }) => {
            setIsLoading(false)
            //console.log(data)
            props.setSearchPac(data)
            //fetchLoadVizov()
        })
        .catch((err) => {
            console.log(err.message);
        });
    }
  }
  let DeletePac = () =>{
    setErrTxt(null)
    setFam(''),setName(''),setOtch(''),setDR(null)
  }
  let AddVizov = (id) => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    /****создание  */
    setErrTxt(null)
    console.log(id+' - '+ip.lk.docdepid)
    setIsLoading(true);
      axios.post(ip.lk.ipaddr+"lkvrachAddVizov/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        patid: id,
        docdep: ip.lk.docdepid
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(({ data }) => {
            setIsLoading(false)
            console.log(data)
            let dann=JSON.parse(data[0].OTVET)
            if (dann.err!=0){
              setErrTxt('Данному пациенту нельзя создать вызов из приложения!')
            }else{
              props.setActiveStoryEx('vizov')
            }
            
        })
        .catch((err) => {
            console.log(err.message);
        });
  }
  let CartPac = (id) => {
    props.setKartPac(id);
    props.setActivePanel('kartpac');
  }
  let HistoryPac = (id) => {
    props.setKartPac(id);
    props.setActivePanel('vsehistory');
  }

  
  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Поиск пациента</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Поиск пациента</PanelHeaderContent>
        </PanelHeader>
        <Group>
        <FormLayout>
            <FormItem top="Фамилия" htmlFor="fam">
              <Input id="fam" type="text" value={fam} onChange={famVvod} getRef={famInput}/>
            </FormItem>
            <FormItem top="Имя" htmlFor="nam">
              <Input id="nam" type="text" value={nam} onChange={nameVvod} getRef={namInput}/>
            </FormItem>
            <FormItem top="Отчество" htmlFor="otch">
              <Input id="otch" type="text" value={otch} onChange={otchVvod} getRef={otchInput}/>
            </FormItem>
            <FormItem top="Дата рождения">
              <div style={{ display: 'flex' }}>
                <LocaleProvider value='ru'>
                  <DateInput
                    value={dr}
                    onChange={setDR}
                  />
                </LocaleProvider>
              </div>
            </FormItem>
        </FormLayout>
        {errtxt && <Div style={{color:"red",fontWeight:"bold"}}>{errtxt}</Div>}
        <Div style={{ display: "flex" }}>
        <Button size="l" stretched style={{ marginRight: 18 }} onClick={() => SerchPac()}>Поиск</Button>
        <Button size="l" appearance="negative" stretched onClick={() => DeletePac()}>Очистить</Button>
        </Div>
      </Group>
      
      {
        props.searchPac && props.searchPac.map((spac,index) => {
          let iconss=''
          if (spac.CUSTOM_ICONS != null){iconss=spac.CUSTOM_ICONS}
          return(
            <Group key={index}>
            <Div>
              <Icon txt={iconss}/>
              <div> {spac.LASTNAME} {spac.FIRSTNAME} {spac.SECONDNAME}</div>
              <div> {spac.BIRTHDATE}</div>
              <div> {spac.AREANUM}</div>
              <div> {spac.ADDRESS}</div>
              <div> {spac.COMMENTAR}</div>
              <Div style={{ display: "flex" }}>
                <Button size="l" stretched onClick={()=>AddVizov(spac.KEYID)}>
                  Добавить вызов врача
                </Button>
              </Div>
              <Div style={{ display: "flex" }}>
                <Button size="l" stretched style={{ marginRight: 18 }} onClick={()=>HistoryPac(spac.KEYID)}>
                  История
                </Button>
                <Button size="l" stretched onClick={()=>CartPac(spac.KEYID)}>
                  Карточка
                </Button>
              </Div>
              {/*<Div style={{ display: "flex" }}>
                <Button size="l" stretched disabled={true}>
                  Запись на прием
                </Button>
              </Div>*/}
              </Div>
            </Group>
          )
        })
      }
      
    </>
  );
};

export default Poiskpac;
