import React from 'react';
import PropTypes from 'prop-types';
import { Group, Panel, PanelHeader, Placeholder, RichCell, View,Avatar, SimpleCell, Header, PanelHeaderBack } from '@vkontakte/vkui';
import { Icon24ChevronRight, Icon28ClipOutline } from '@vkontakte/icons';
import { Icon28MessageOutline } from '@vkontakte/icons';
import { useSelector, useDispatch } from "react-redux";
import VizovGl from './VizovGl';
import Kartpac from '../patient/Kartpac';
import Lab from '../history/Lab';
import Res from '../history/Research';
import Vac from '../history/Vac';
import Visit from '../history/Talon';
import VseHistory from '../history/VseHistory';


const Vizov = (props) => {

	  const [activePanel, setActivePanel] = React.useState('vizovgl');
	  const [kartpac, setKartPac] = React.useState(null);
	  const [history, setIsHistory] = React.useState(null);
	  const [keyhistory, setKeyHistory] = React.useState(null);

    return(
        <View id="vizov" activePanel="vizov">
			  <Panel id="vizov">
			  	<View activePanel={activePanel}>
					<Panel id="vizovgl">
						<VizovGl setActivePanel={setActivePanel} setKartPac={setKartPac} setPing={props.setPing}/>
					</Panel>
					<Panel id="kartpac">
						<Kartpac setActivePanel={setActivePanel} patid={kartpac} panelBack={'vizovgl'} setPing={props.setPing}/>
					</Panel>

					<Panel id="vsehistory">
						<VseHistory setActivePanel={setActivePanel} patid={kartpac} panelBack={'vizovgl'} setIsHistory={setIsHistory} history={history} setKeyHistory={setKeyHistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="lab">
						<Lab setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="res">
						<Res setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="vac">
						<Vac setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
					<Panel id="visit">
						<Visit setActivePanel={setActivePanel} patid={kartpac} panelBack={'vsehistory'} id={keyhistory} setPing={props.setPing}/>
					</Panel>
				</View>
			  </Panel>
			</View>
    )
}

export default Vizov;