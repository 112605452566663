import React from 'react';
import PropTypes from 'prop-types';

import {  Button,  FormItem,  FormLayout,  Group,  Input,  Panel, PanelHeader } from '@vkontakte/vkui';
import loads from '../img/cat-loading.gif';
import sgdkbjpeg from '../img/SGDKB.png';
import "./LoadUser.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAddDocDep, setAddManId } from './../redux/action/lk';
import {Ruslat} from '../function/ruslat'
import { PingTest } from '../function/ping';

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');


const LoadUser = props => {
	const dispatch = useDispatch();

	const ip = useSelector(({lk}) => {
		return{
		  lk: lk
		}
	  });
	  //console.log(ip)
	const [pass, setPass] = React.useState('');
	let passInput = React.createRef();
	let passVvod = () => {
		setPass(passInput.current.value)
	   }
	let sutki;
	let d = new Date()
	let h = d.getHours()
	if (h>=0 && h<4){sutki='Доброй ночи,'}
	else if(h>=4 && h<11){sutki='Доброе утро,'}
	else if (h>=11 && h<16){sutki='Добрый день,'}
	else if (h>=16 && h<23){sutki='Добрый вечер,'}
	else if (h>=23 && h<24){sutki='Доброй ночи,'}

	const [loadData, setLoadData] = React.useState(null);
	const [load, setLoad] = React.useState(true);
	const [err, setErr] = React.useState(null);

	if(loadData==null &&  props.fetchedUser!=null){

		//console.log(ip.lk.ipaddr+"lkvrachUser/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+props.fetchedUser.id)
		//console.log(ip.lk.kkk+'---'+ip.lk.pril+'---'+fff)
		//console.log(ip.lk.ipaddr+"lkvrachUser/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+props.fetchedUser.id)

		axios.get(ip.lk.ipaddr+"lkvrachUser/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+props.fetchedUser.id)
			.then(({ data }) => {
				if(data[0].ERRCODE==0 && data[0].STATUS==1){
					dispatch(setAddManId(data[0]))
					setLoadData(props.fetchedUser)
					/************************************/
					axios.get(ip.lk.ipaddr+"lkvrachDocdep/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+props.fetchedUser.id)
					.then(({ data }) => {
						//console.log(data)
						dispatch(setAddDocDep(data))
						setLoad(false)
					})
					.catch((err) => {
						console.log(err.message);
					});
					/************************************/
				}else if(data[0].ERRCODE==0 && data[0].STATUS==0){
					//console.log('Пользователь заблокирован! Обратитесь в ИТ отдел.')
					//setErr(99)
					setErr('Пользователь заблокирован! Обратитесь в ИТ отдел.')
				}else{
					setErr(data[0].TEXT)
				}
			})
			.catch((err) => {
				console.log(err.message);
			})

	}

	const BtmPass = () => {
		
		let vk=props.fetchedUser.id;
		PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,vk,3,props.setPing)
		setLoad(true);
		axios.post(ip.lk.ipaddr+"lkvrachlogin/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+vk,{
			par1: md5(Ruslat(pass)),
			par2: md5(vk)
			}, {
				headers: {
				'Content-Type': 'multipart/form-data'
				}
			})
			.then(({ data }) => {
				setLoad(false)
				console.log('pass - '+data[0].COL)
				if(data[0].COL == 1){
					props.setActivePanelGl('sgdkb')
				}else{
					setErr('Вы ввели не правильный пароль! Обратитесь в ИТ отдел.')
				}
			})
			.catch((err) => {
				console.log(err.message);
			});

		//

	}

    return(
	<Panel id={props.id}>
	<div style={{padding:'50px 0 10px 0'}}></div>
		{
			load && 
			<Group>
				<div style={{textAlign:'center'}}>
				<div style={{padding:'10px 0 10px 0'}}>Подождите, идет загрузка данных</div>
				<img src={loads} alt="LoadCat"/>
				<div>{err}</div>
				</div>
			</Group>
		}
		{
			loadData &&
			<Group>
				<div style={{textAlign:'center'}}>
				<img className="sgdkb" src={sgdkbjpeg} alt="sgdkbjpegalt"/>
				<div style={{padding:'0 0 10px 0'}}>{sutki} {`${loadData.first_name} ${loadData.last_name}`}</div>
				<div style={{padding:'10px 0 10px 0'}}>Добро пожаловать в личный кабинет врача</div>
				<div style={{color:'red'}}>{err}</div>
				<FormLayout>
					<FormItem top="Пароль" htmlFor="pass">
						<Input id="pass" type="text" value={pass} onChange={passVvod} getRef={passInput}/>
					</FormItem>
				</FormLayout>
				<Button onClick={() => BtmPass()}>Войти</Button>
				</div>
			</Group>
		}


	{/*props.fetchedUser ? 
		(<Group>
		<div style={{textAlign:'center'}}>
		<img className="sgdkb" src={sgdkbjpeg} alt="sgdkbjpegalt"/>
		<div style={{padding:'0 0 10px 0'}}>{sutki} {`${props.fetchedUser.first_name} ${props.fetchedUser.last_name}`}</div>
		<div style={{padding:'10px 0 10px 0'}}>Добро пожаловать в личный кабинет врача</div>
		<Button onClick={props.go} data-to="sgdkb">Войти</Button>
		</div>
		</Group>) : (
			<Group>
		<div style={{textAlign:'center'}}>
		<div style={{padding:'10px 0 10px 0'}}>Подождите, идет загрузка данных</div>
		<img src={loads} alt="LoadCat"/>
		</div>
		</Group>
		)*/}
		
	</Panel>
)};

LoadUser.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
};

export default LoadUser;

