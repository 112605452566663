import React from "react";
import {
  Div,
  Group,
  Header,
  Panel,
  PanelHeader,
  PanelHeaderContent,
  View,
} from "@vkontakte/vkui";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
//import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import img from '../../img/metkanew.png'




var md5 = require("md5");
var fff = new Date().toISOString().slice(0, 10).split("-").reverse().join(".");

const Home = (props) => {
  const dispatch = useDispatch();

  const ip = useSelector(({ lk }) => {
    return {
      lk: lk,
    };
  });
  //console.log(ip)


  /*let errtxt = "";
  if (props.err == 99) {
    errtxt = (
      <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
        Ошибка! Пользователь заблокирован! Обратитесь в ИТ отдел.
      </div>
    );
  } else if (props.err == 100) {
    errtxt = (
      <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
        Ошибка! Пользователь ВК не определился! Обратитесь в ИТ отдел.
      </div>
    );
  } else if (props.err == 101) {
    errtxt = (
      <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
        Ошибка! Код проверки не совпал! Обратитесь в ИТ отдел.
      </div>
    );
  } else {
    errtxt = <div>{props.err}</div>;
  }*/

  const position = [64.563386, 39.823781]; // [latitude, longitude]
  const position2 = [64.549039, 39.762552];
  const position3 = [64.563386, 39.823781];
  const zoomLevel = 13;
  const redColor = { color: 'red' }


//[64.563386, 39.823781] 8.1386, 5.1026
  return (
    <View id="home" activePanel="home">
      <Panel id="home">
        <PanelHeader>
          <PanelHeaderContent status={ip.lk.docdeptxt}>СГДКБ</PanelHeaderContent>
        </PanelHeader>
        {/*<Group >
        
        Вызова на карте (в разработке)
        <MapContainer center={position} zoom={zoomLevel} style={{ height: "50vh", width: "100%" }} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
          <Marker position={position2}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>

        </MapContainer>

        </Group>*/}


        <Group header={<Header mode="secondary">Новости</Header>}>
      <Div>

      В "История лечения пациента" отображается: Вакцинация, Протоколы, Лабаратория, Исследования.
      </Div>
      
      <Div>
      1. Вызов врача
      <div>+ Обработка вызова</div>
      <div>+ Просмотр Истории лечения пациента</div>
      <div>+ Просмотр Карточки пациента</div>
      <div>- Заполение и обновление стат талона</div>
      <div>- Добавление, заполнение и обновление протокола</div>
      <div>- Запись на прием пациента</div>
      </Div>
      <Div>
      2. Ведомость врача
      <div>+ Просмотр Истории лечения пациента</div>
      <div>+ Просмотр Карточки пациента</div>
      <div>- Заполнение и обновление стат талона</div>
      <div>- Добавление, заполнение и обновление протокола</div>
      </Div>
      <Div>
      3. Поиск пациента
      <div>+ Добавление вызова врача на дом пациенту</div>
      <div>+ Просмотр Истории лечения пациента</div>
      <div>+ Просмотр Карточки пациента</div>
      <div>- Запись на прием пациента</div>
      </Div>
      <Div>
      4. Настройки
      <div>+ Включение уведомления при поступлении новых вызовов</div>
      </Div>
      <Div>
      5. Смена врача
      <div>+ Можно поменять врача, если их много</div>
      </Div>
      
        </Group>

      </Panel>
    </View>
  );
};

export default Home;
