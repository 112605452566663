const initialState={
    vpril:1001,
    kkk: '6543gf#@%fdghh!%gfhrerty$#$@!@#fsdfhgfdg$%gfdg2',
    pril:'VkMiniApps',
    ipaddr2:'https://apilk4.sgdkb.ru/',
    vk:null,
    idman:{},
    docdep:[],
    docdepid:0,
    docdeptxt:'',
    ping:45,
    activePanelGl:'login',
    vizova:[],vizovaupdate:{},
    vedomost:[],vedomostupdate:{},
    patient:[],
    istoriapac:[],
    login:false,
    ipaddr:'https://apilk4.sgdkb.ru/',
    oldstr:'',
    kartpacid:0
    
}
/*
*ping 1-нет 0-есть
*/

const lkReducer = (state = initialState, action) => {
    if (action.type === 'ADD_VK'){
        return {
            ...state,
            vk:action.payload,
        }
     }else if (action.type === 'ADD_MAN_ID'){
            return {
                ...state,
                idman:action.payload
            }
     }else if (action.type === 'ADD_DOCDEP'){
        let doctxt;
        for (let i=0; i<action.payload.length; i++){
            if (state.idman.DOCDEPID == action.payload[i].DOCDEPID){
                doctxt=action.payload[i].DOCDEPID+' - '+action.payload[i].DOC;
            }
        }
    
        return {
            ...state,
            docdep:action.payload,
            docdepid:state.idman.DOCDEPID,
            docdeptxt:doctxt
        }
     }else if (action.type === 'UPDATE_DOCDEP_ID'){
        return {
            ...state,
            docdepid:action.payload
        }
     }else if (action.type === 'UPDATE_DOCDEP_TEXT'){
        return {
            ...state,
            docdeptxt:action.payload
        }
     }else if (action.type === 'UPDATE_NOTIFF'){
        return {
            ...state,
            idman:action.payload.dat
        }
     }else if (action.type === 'KARTPACID'){
        return {
            ...state,
            kartpacid:action.payload
        }
     }else if (action.type === 'PING'){
        return {
            ...state,
            ping:action.payload
        }
     }else if (action.type === 'ACTIONPANELGLAV'){
        console.log('1111')
        return {
            ...state,
            activePanelGl:action.payload
        }
     }
 return state;
}

export default lkReducer;