import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import LoadUser from './panels/LoadUser';
import { setAddVk } from './redux/action/lk';
import { useSelector, useDispatch } from "react-redux";
import Example from './panels/Example';
import ErrPing from './panels/ErrPing';

const App = () => {
	//console.log('app')
	const dispatch = useDispatch();
	const [activePanel, setActivePanel] = useState('login');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(null);
	const [ping, setPing] = useState(0);

	useEffect(() => {
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
			dispatch(setAddVk(user.id))
		}
		fetchData();
	}, []);



	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
		//console.log(e.currentTarget.dataset.to)
	};
	/*if (ping!=0) {
		return (
			<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View activePanel='errping'>
								<ErrPing id='errping' fetchedUser={fetchedUser} go={go} setPing={setPing}/>
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
		);
	  }*/

	return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Example id='sgdkb' fetchedUser={fetchedUser} go={go} setPing={setPing}/>
								<LoadUser id='login' fetchedUser={fetchedUser} go={go} setActivePanelGl={setActivePanel} setPing={setPing}/>
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
