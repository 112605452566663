import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Checkbox, Div, Group, Header, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { setAddDocDep, setAddManId } from "../../redux/action/lk";
import bridge from '@vkontakte/vk-bridge';
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Settings = (props) => {
    const dispatch = useDispatch();
    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(false);

  const ckikNotifButton = (param) => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    console.log(param)
    setIsLoading(true)
    if(param!=1){
        axios.post(ip.lk.ipaddr+"lkvrachUpdateNotif/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
            notiff: 0
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(({ data }) => {
            console.log(data)
            axios.get(ip.lk.ipaddr+"lkvrachUser/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk)
			.then(({ data }) => {
                console.log(data)
				dispatch(setAddManId(data[0]))
                setIsLoading(false)
			})
			.catch((err) => {
				console.log(err.message);
                setIsLoading(false)
			})
            
        })
        .catch((err) => {
            console.log(err.message);
            setIsLoading(false)
        });
    }else{
        fetchDataNotifAdd()
    }

    async function fetchDataNotifAdd() {
        bridge.send('VKWebAppAllowNotifications')
        .then((data) => { 
        console.log(data);
        if(data.result){
            axios.post(ip.lk.ipaddr+"lkvrachUpdateNotif/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
                notiff: 1
            }, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                axios.get(ip.lk.ipaddr+"lkvrachUser/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk)
                .then(({ data }) => {
                    console.log(data)
                    dispatch(setAddManId(data[0]))
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err.message);
                    setIsLoading(false)
                })
            })
            .catch((err) => {
                console.log(err.message);
                setIsLoading(false)
            });
        }
        })
        .catch((error) => {
        // Ошибка
        console.log(error.error_type);
        setIsLoading(false)
        });
    }
 
  }

  


  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Настройки</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Настройки</PanelHeaderContent>
        </PanelHeader>
        <Group header={<Header>Уведомления</Header>}>
        {
            ip.lk.idman.NOTIFICATIONS!=1 ? (
                <Checkbox onClick={() => ckikNotifButton(1)}>Вызов врача на дом</Checkbox>
            ):(
                <Checkbox defaultChecked onClick={() => ckikNotifButton(0)}>Вызов врача на дом</Checkbox>
            )
        }
      
      </Group>
    </>
  );
};

export default Settings;
