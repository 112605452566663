import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Div, Group, PanelHeader, PanelHeaderBack, PanelHeaderContent, SimpleCell, Spinner } from "@vkontakte/vkui";
import { PingTest } from "../../function/ping";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const Karantin = (props) => {

    const ip = useSelector(({lk}) => {
          return{
            lk: lk
          }});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isVac, setIsVac] = React.useState(null);

  const fetchLoadPatient = () => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    setIsVac(null)
    setIsLoading(true);
console.log(ip.lk.ipaddr+"lkvrachKarantin/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk)
    axios.post(ip.lk.ipaddr+"lkvrachKarantin/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        patid: props.patid,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(({ data }) => {
        setIsLoading(false)
        setIsVac(JSON.parse(data[0].DANN))
        console.log(JSON.parse(data[0].DANN))
        console.log(data)
    })
    .catch((err) => {
        console.log(err.message);
    });
  };

  React.useEffect(fetchLoadPatient, []);
 

  if (isLoading) {
    return (
        <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Карантины</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
        </>
    );
  }

  return (
    <>
        <PanelHeader
        before={
          <PanelHeaderBack onClick={() => props.setActivePanel(props.panelBack)} />
        }>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Карантины</PanelHeaderContent>
        </PanelHeader>
        <Group>
        {
            isVac && isVac.map((vacs,index) => {
            console.log(vacs)
            return(
              <div key={index} >
                <div style={{textAlign: 'center',fontWeight: "bold",paddingBottom:5, backgroundColor:"#DFDAFF",color:"black"}}>{vacs.disease_text}</div>
                {vacs.vac && vacs.vac.map((etap) => {
                    return(
                        <div style={{backgroundColor:"#FFE5CB",color:"black" }} key={etap.disease_id}>{etap.act} - факт {etap.dat} - план {etap.plan_dat}</div>
                    )
                })}
              </div>
            )
          })
        }
      </Group>
    </>
  );
};

export default Karantin;
