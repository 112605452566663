import axios from "axios";

var md5 = require('md5');

export function PingTest(ipaddr,kkk,pril,fff,vkid,type,setPing){
    axios.get(ipaddr+"ping/"+type+"/"+md5(kkk+''+pril+''+fff)+"/"+vkid)
			.then(({ data }) => {
                setPing(data.status)
			})
			.catch((err) => {
				console.log(err.message);
                setPing(1)
			})
}

