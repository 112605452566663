import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Card, CardGrid, DateInput, Div,  Group, IconButton, PanelHeader, PanelHeaderBack, PanelHeaderContent, Placeholder, Spinner, Header } from "@vkontakte/vkui";
import { Icon16Replay, Icon28MessageOutline, Icon28Replay } from "@vkontakte/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setLoadVizov } from "../../redux/action/lk";
import { PingTest } from "../../function/ping";
import Icon from "../Icon";

var md5 = require('md5');
var fff = new Date().toISOString().slice(0, 10).split('-').reverse().join('.');

const VizovGl = (props) => {
  const dispatch = useDispatch();
  const ip = useSelector(({lk}) => {
        return{
          lk: lk
        }});
  //console.log('--***--//**')
  //console.log(ip)

  const [vizova, setVizova] = React.useState([]);
  const [vizovaLoadDat, setVizovaLoadDat] = React.useState(null);
  const [karantin, setKarantin] = React.useState(0);

  const [popout, setPopout] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  var dat1 =new Date() 
    var dat21 =new Date()
    var dat2 =new Date(dat21.setDate(dat21.getDate() - 1)) 
    var dat31 =new Date()
    var dat3 =new Date(dat31.setDate(dat31.getDate() + 1))
    const [dateminus, setDateMinus] = React.useState(dat2);
    const [date, setDate] = React.useState(dat1);
    const [dateplus, setDatePlus] = React.useState(dat3);
    const [datePreobrMinus, setDatePreobrMinus] = React.useState(formatDate(dat2));
    const [datePreobr, setDatePreobr] = React.useState(formatDate(dat1));
    const [datePreobrPlus, setDatePreobrPlus] = React.useState(formatDate(dat3));

  
     /**преобразование даты в формат 01.01.2022 */
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }
  /************************** */
  
  const minusData = () => {
    setVizova([])
    var dats1 = dateminus;
    setDate(dats1);setDatePreobr(formatDate(dats1));
    var dats21 = dateminus;
    var dats2 = new Date(dats21.setDate(dats21.getDate() - 1))
    setDateMinus(dats2);setDatePreobrMinus(formatDate(dats2));
    var dats31 = dateminus;
    var dats32 = dats31.getDate() + 2
    var dats33 = dats31.setDate(dats32)
    var dats3 = new Date(dats33)
    //var dats3 = new Date(dats31.setDate(dats31.getDate() ))
    setDatePlus(dats3);setDatePreobrPlus(formatDate(dats3));

            
            fetchLoadVizov(datePreobrMinus)
 
  }
  
  const plusData = () => {
    setVizova([])
    var dats1 = dateplus;
    setDate(dats1);setDatePreobr(formatDate(dats1));
    var dats21 = dateplus;
    var dats2 = new Date(dats21.setDate(dats21.getDate() - 1))
    setDateMinus(dats2);setDatePreobrMinus(formatDate(dats2));
    var dats31 = dateplus;
    var dats32 = dats31.getDate() + 2
    var dats33 = dats31.setDate(dats32)
    var dats3 = new Date(dats33)
    //var dats3 = new Date(dats31.setDate(dats31.getDate() ))
    setDatePlus(dats3);setDatePreobrPlus(formatDate(dats3));

        
        fetchLoadVizov(datePreobrPlus)

  }
  
  const fetchLoadVizov = (dattt) => {
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    setVizova([])
    setIsLoading(true);

    if (dattt==null){dattt=datePreobr}
    let DLV = new Date();
    let DLVFrom = DLV.getDate() + "." + (DLV.getMonth()+1) + "." + DLV.getFullYear() + " " + DLV.getHours() + ":" + ((DLV.getMinutes()<10?'0':'') + DLV.getMinutes());
    axios.post(ip.lk.ipaddr+"lkvrachVizov/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        docdepid: ip.lk.docdepid,
        dat: dattt
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then(({ data }) => {
        setIsLoading(false)
        //dispatch(setLoadVizov(data));
        /*setVizova(data)
        setVizovaLoadDat(DLVFrom)
        console.log(data)*/
        setVizova(JSON.parse(data[0].DANN))
        setVizovaLoadDat(DLVFrom)
        console.log(JSON.parse(data[0].DANN))
    })
    .catch((err) => {
        console.log(err.message);
    });
  };
  
  React.useEffect(fetchLoadVizov, []);

  const KartPacClick = (patid) => {
/*onClick={() => props.setActivePanel("spravki")}*/
//console.log(patid)
    props.setKartPac(patid)
    props.setActivePanel("kartpac")
  }
  const HistoryClick = (patid) => {
    props.setKartPac(patid)
    props.setActivePanel("vsehistory")
  }

  const KarantinClick = (patid) => {
    props.setKartPac(patid)
    props.setActivePanel("karantin")
  }


  const btnObrabotan = (rnkey,stat) =>{
    /**проверка на доступность */
    PingTest(ip.lk.ipaddr,ip.lk.kkk,ip.lk.pril,fff,ip.lk.vk,3,props.setPing)
    
    setVizova([])
    //console.log(rnkey)
    //console.log(stat)
    var exam=0
    var stat_update=0
    if(stat==1){
      stat_update=0; exam=47607;
    }else{
      stat_update=1; exam=47628;
    }
    setIsLoading(true);
    axios.post(ip.lk.ipaddr+"lkvrachVizovObr/3/"+md5(ip.lk.kkk+''+ip.lk.pril+''+fff)+"/"+ip.lk.vk,{
        rnkey: rnkey,
        exam: exam,
        stat: stat_update
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      .then(({ data }) => {
        //console.log(data)
          setIsLoading(false)
          fetchLoadVizov()
      })
      .catch((err) => {
          console.log(err.message);
      });

  }


if (isLoading) {
			return <>
        <PanelHeader>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Вызовы врача</PanelHeaderContent>
        </PanelHeader>
			  <Spinner size="large" style={{ marginTop: 20 }}/>
			  </>
		  } 

  return (
    <>
        <PanelHeader>
          <PanelHeaderContent status={ip.lk.docdeptxt}>Вызовы врача</PanelHeaderContent>
        </PanelHeader>
        <Group>
          <div style={{ width: "100%", margin: "0 auto", padding: 10 }}>
            <div style={{ float: "left", width: "33%", textAlign: "center",fontSize:20,opacity:0.2}} onClick={ () => minusData()}>
              {datePreobrMinus}
            </div>
            <div style={{ float: "right", width: "33%", textAlign: "center",fontSize:20,opacity:0.2 }} onClick={ () => plusData()}>
              {datePreobrPlus}
            </div>
            <div style={{ margin: "0 33% 0 33%", textAlign: "center",fontSize:20 }} onClick={ () => minusData()}>
              {datePreobr}
            </div>
          </div>
          <Header
            mode="primary"
            aside={
              <Icon28Replay onClick={ () => fetchLoadVizov()}/>
            }
            subtitle={vizovaLoadDat}
          >
            Количество - {vizova && vizova.length}
          </Header>

          <CardGrid size="l">

          {vizova ? vizova.map((viz, index) => {
              
              let color='#f2f3f5'
              if(viz.examid == 47628){color='#BBFFBB'}else{color='#f2f3f5'}
              let iconss=''
              if (viz.icon != null){iconss=viz.icon}
              const KARANTIN="KARANTIN";
            return(
              <Card  style={{backgroundColor:color}} key={index}>
                  <Div style={{color:"black"}}>
                    <Icon txt={iconss} kar={setKarantin}/>
                    <div><b>{viz.fio}</b></div>
                    <div>{viz.dr}, {viz.voz}</div>
                    <div>{viz.polis}</div>
                    <div>{viz.tel1}</div>
                    {viz.tel2  && <div style={{color:"red"}}>{viz.tel2}</div>}
                    <div>{viz.ul} {viz.dom} {viz.korp} {viz.addr1}</div>
                    {viz.addr2 != null ? <div style={{color:"red"}}>{viz.addr2}</div> : null}
                    <div>{viz.commen}</div>
                  </Div>
                    <Div>
                      <Button stretched size="l" onClick={ () => btnObrabotan(viz.rnkey,viz.stat,viz.examid)}>{viz.examid==47628 ? (<>Отменить обработку вызова</>) : (<>Вызов обработан</>)}</Button>
                    </Div>
                    {/*<Div style={{ display: "flex" }}>
                      <Button size="l" stretched style={{ marginRight: 18 }} disabled={true}>
                        Талон
                      </Button>
                      <Button size="l" stretched disabled={true}>
                        Протокол
                      </Button>
                    </Div>*/}
                    <Div style={{ display: "flex" }}>
                      <Button size="l" stretched style={{ marginRight: 18 }} onClick={() => HistoryClick(viz.pid)}>
                        История
                      </Button>
                      <Button size="l" stretched onClick={() => KartPacClick(viz.pid)}>
                        Карточка
                      </Button>
                    </Div>
                    {iconss.includes(KARANTIN) ? (<Div>
                      <Button stretched size="l" appearance="negative" onClick={() => KarantinClick(viz.pid)}>КАРАНТИН</Button>
                    </Div>) : (null)}
                    {/*<Div>
                      <Button stretched size="l" disabled={true}>Запись на прием</Button>
                    </Div>*/}
                  </Card>
              )}
          ) : null}

          </CardGrid>
        </Group>
        </>
  );
};

export default VizovGl;
